import {Injectable} from '@angular/core';
import {of, Subject,Observable} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  throttleTime,
} from 'rxjs/operators';

import {distinctUntilKeysChanged} from '@helpers/distinct-until-keys-changes';
import {LocationDto} from '@models/location';

import {GeolocationService} from './geolocation.service';

@Injectable({
  providedIn: 'root',
})
export class GeolocationStoreService {

  private updateLocation$: Subject<void>;
  location$: Observable<LocationDto | null>;
  safeLocation$: Observable<LocationDto | null>;
  position$: Observable<GeolocationCoordinates | null>;

  constructor(
    private readonly geolocationService: GeolocationService,
  ) {
  }

  init(): void {
    this.updateLocation$ = new Subject<void>();
    this.position$ = this.getUserPosition();
    this.location$ = this.getLocation(this.position$);
    this.safeLocation$ = this.location$.pipe(catchError(() => of(null)));
  }

  private getLocation(position$: Observable<GeolocationCoordinates | null>): Observable<LocationDto | null> {
    return position$.pipe(
      switchMap(position => {
        if (!position) {
          return of(null);
        }
        const {longitude, latitude} = position;
        return this.geolocationService.getLocationAddress({lng: longitude, lat: latitude}).pipe(
          map(address => ({address, lat: latitude, lng: longitude})),
        );
      }),
      shareReplay(1),
    );
  }

  private getUserPosition(): Observable<GeolocationCoordinates | null> {
    return this.updateLocation$.pipe(
      startWith(null),
      throttleTime(300),
      switchMap(_ => this.geolocationService.getGeolocationPosition()),
      map(pos => pos ? pos.coords : null),
      distinctUntilChanged(distinctUntilKeysChanged),
      shareReplay(1),
    );
  }

  updateLocation(): void {
    this.updateLocation$.next();
  }

  destroy(): void {
    this.updateLocation$.complete();
  }
}
